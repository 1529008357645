@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html { height: 100%; }

body {
  font-family:  "Inter", -apple-system, "avenir next", avenir, roboto, noto, ubuntu, "helvetica neue", helvetica, sans-serif;
  font-size: 1.375rem;
  @media (min-width: 600px) {
    font-size: 1rem;
  }
  line-height: 2;
  @media (max-width: 600px) {
    line-height: 1.5;
  }
  margin: 0;
  min-height: 100%;
}
pre, code {
  font-family: "Anonymous Pro", "Courier New", monospace;
  font-size: 1.375rem;
  @media (min-width: 600px) {
    font-size: 0.9rem;
  }
}

h2, h3, h4, h5 { margin-top: 0.5em; margin-bottom:0em;}

hr { margin: 1em 0; }

hr.page-break {
  text-align: center;
  border: 0;

  &:before { content: '-----' }
  &:after { content: attr(data-content) '-----' }
}

p { margin: 0.5em 0; }

li { margin: 0.4em 0; }

.w {
  max-width: 720px;
  margin: 0 auto;
  /* padding: 2.5em 2em; */
  padding: 0.4em;
}

table, th, td {
  width: 100%;
  border: thin solid black;
  border-collapse: collapse;
  padding: 0.4em;
}

div.highlighter-rouge pre code, pre code.highlighter-rouge {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

blockquote {
  font-style: italic;
  border: thin solid black;
  padding: 1em;

  p { margin: 0; }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
html {
    --bg: #FFF;
    --bg-secondary: #f8f8f8;
    --headings: #0E2803;
    --text: #333;
    --links: #A6C976;
    --highlight: #C79B54;
    --code-text: #9D174D;
    --share-text: #999;
}
// -------------- THEME SWITCHER -------------- //
@mixin dark-appearance {
  html, body  {
      --headings: #C79B54;
      --links: #A6C976;
      --highlight: #489A09;
      --bg: #0E2803;
      --bg-secondary: #210402;
      --text: #F8F7F1;
      --code-text: #A6C976;
      --share-text: #C4C4C4;
  };
}
html[data-theme="dark"] { @include dark-appearance; }

@media (prefers-color-scheme: dark) {
  body[data-theme="auto"] { @include dark-appearance; }
}
// -------------------------------------------- //

html, body {
    background-color: var(--bg);
    color: var(--text);
}
h1, h2, h3, h4, h5, h6 {
    color: var(--headings);
}
p, strong, b, em, small, li, hr, table, figcaption {
    color: var(--text);
}
.highlight, pre code, blockquote {
  border-radius: 0.5em;
}
blockquote {
  background-color: var(--bg-secondary);
  border: 1px var(--border) solid;
}
a {
  color: var(--links);
}
*:target { background-color: var(--bg-secondary); }

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
    transition: all 250ms !important;
    transition-delay: 0 !important;
}

.theme-toggle {
  color: var(--text);
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
  margin: 1em;
  position: fixed;
  right: 0;
  top: 0;
  border: 2px transparent solid;
  outline: none;
}

.theme-toggle:hover {
  color: var(--links);
  outline: none;
}
.theme-toggle:focus {
  outline: none;
}
.dashed {
  border-top: 1px var(--text) dashed;
  margin: 0.5em 0;
}
/*
mark {
  padding: 0.4em;
  background-color: var(--highlight);
  font-size: 1.375em;
  @media (min-width: 600px) {
    font-size: 0.6rem;
  }
  letter-spacing: 1px;
}*/
mark {
  padding: 2em;
  /* background-color: var(--highlight); */
  background: linear-gradient(to top left, #FBFBFA, #FFF);
  /*font-size: 1.375em;*/
  /* @media (min-width: 600px) {
    font-size: 0.6em;
  } */
  letter-spacing: 1px;
   border-radius: 1em; 
}

.post-date {
  color: var(--secondary-text);
  //margin-bottom: 1rem;
  font-size: 1.375em;
}
.home-date {
  font-family: monospace;
}
.post-list-item a {
  text-decoration: none;
}
.text-bold {
  font-weight: bold;
}
.text-upcase {
  text-transform: uppercase;
}
p code, li code {
  background-color: var(--bg-secondary);
  padding: 0.2rem;
  color: var(--code-text);
  font-weight: bold;
}
.post-title {
  margin-bottom:-1.5rem;
}
.project-title {
  margin-bottom: 0.8rem;
}
.credits {
  font-size: 1.375em;
    @media (min-width: 600px) {
    font-size: 0.8rem;
  }
  color: var(--text);
  margin: 8em auto -4em auto;
  text-align: center;
  a {
    color: var(--text);
    text-decoration: none;
    font-weight: bold;
  }
  a:hover {
    color: var(--links);
  }
}



#menu {
 
    a{
            text-decoration: none;
            font-weight: normal;
            /*position: absolute; */
            /* bottom:-0.4em; */
    }  
    a.disabled-link{
            pointer-events: none;
            cursor: default;
            color: #C79B54;
    }     
}


header{
  margin-bottom: 1.5em;
}

#index-logo{
  height: 8em;
  /* @media (max-width: 600px) {
      height: 5em;
      display: none;
  }*/
  margin-top: 2rem;
  margin-bottom: 1rem;
}

div.pagination {

  a {
    text-decoration: none;
    font-weight: normal;
  }

}

.badge-holder{
  display:flex;
  justify-content:safe center;
  padding:3px;
}
.badge {
  background-color: #062903;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
} 